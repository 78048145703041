import React, { useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { educationsData } from "../data/educationsData";
import EducationItem from "../components/EducationItem";
// icons
import { GiSandsOfTime } from "react-icons/gi";

const Education = () => {
  useEffect(() => {
    // Set Meta tags
    document.title = "Education";
    document.querySelector('meta[name="title"]').setAttribute("content", "Education");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
      Bachelor, degree, Computer Engineering, American University of The Middle East, Education`
    );
  }, []);

  return (
    <div id="education" className="education">
      <h1>Education</h1>
      <div className="timeline">
        <VerticalTimeline>
          <VerticalTimelineElement
            iconStyle={{ background: "#FFFFF0", color: "#BEBEBE" }}
            icon={<GiSandsOfTime />}
          />
          {educationsData.map((education, index) => (
            <EducationItem key={index} education={education} />
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Education;
