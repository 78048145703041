import React, { useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { experiencesData } from "../data/experiencesData";
import ExperienceItem from "../components/ExperienceItem";

// icons
import { GiSandsOfTime } from "react-icons/gi";

const Experience = () => {
  useEffect(() => {
    // Set Meta tags
    document.title = "Experience";
    document.querySelector('meta[name="title"]').setAttribute("content", "Experience");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
      Phinests, SOC Analyst, Kuwait Finance House, KFH, Banking, Cybersecurity, Administrative, counseling, Experience`
    );
  }, []);

  return (
    <div id="experience" className="experience">
      <h1>Experience</h1>
      <div className="timeline">
        <VerticalTimeline>
          <VerticalTimelineElement
            iconStyle={{ background: "#FFFFF0", color: "#BEBEBE" }}
            icon={<GiSandsOfTime />}
          />

          {experiencesData.map((experience, index) => (
            <ExperienceItem key={index} experience={experience} />
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Experience;
