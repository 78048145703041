import React, { useState, useEffect } from "react";
import { FiGithub, FiLinkedin } from "react-icons/fi";
import { FaStackOverflow } from "react-icons/fa";
import buyMeACoffee from "../components/pictures/logos/buyMeACoffee.png";

export default function Contact () {
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        // Set Meta tags
        document.title = "Contact";
        document.querySelector('meta[name="title"]').setAttribute("content", "Contact");
        document.querySelector('meta[name="keywords"]').setAttribute("content", 
          `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
          Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
          Contact, hello, github, stack over flow, linkedIn, buy me a coffee, contact form`
        );
    }, []);

    return (
        <div id="contact" className="contact">
            <h1>Say Hello:</h1>
            <div className="main-contact">
                <div className="contact-form">
                    {/* <p className="mb-5">Thank you for your interest in my portfolio. I'm excited to hear from you, please fill out the form below to get in touch.</p> */}
                    <p className="mb-5"><span>✿</span> Get in touch:</p>

                    <form action="https://formspree.io/f/xqkrjwzk" method="post" onSubmit={ () => setSubmitLoading(true)}>
                        <div className="row name-email">
                            <div className="col">   
                                <div data-mdb-input-init className="form-outline mb-3">
                                    <input type="text" name="name" id="form3Example1" className="form-control" placeholder="Name" required/>
                                </div>
                            </div> 
                            <div className="col">
                                <div data-mdb-input-init className="form-outline mb-3">
                                    <input type="email" name="email" id="form3Example2" className="form-control" placeholder="Email" required/>
                                </div>
                            </div>
                        </div>

                        <div data-mdb-input-init className="form-outline mb-3">
                            <textarea id="form3Example3" name="message" rows={5} className="form-control" placeholder="Your Message..." required 
                                defaultValue="I would like to request a copy of your CV to get a better understanding of your professional journey and achievements." 
                            />
                        </div>

                        <button type="submit" className="btn send-btn btn-block">{submitLoading ? "Loading..." : "Send"}</button>
                    </form>                    
                </div>
                <div className="contact-container mb-3">
                    <p className="mt-5"><span>✿</span> Or Follow My Journey On:</p>
                    <a href="https://www.linkedin.com/in/hessah-al-hamadi-715795170/" target="_blank" rel="noreferrer"><FiLinkedin /><div className="hide">LinkedIn</div></a>
                    <a href="https://github.com/Hessah95" target="_blank" rel="noreferrer"><FiGithub /><div className="hide">GitHub</div></a>
                    <a href="https://stackoverflow.com/users/11878732/hessah" target="_blank" rel="noreferrer"><FaStackOverflow /><div className="hide">StackOverflow</div></a>
                </div>
                <div className="mt-5">
                    <a href="https://www.buymeacoffee.com/Hessah" target="_blank" rel="noopener noreferrer">
                        <img src={buyMeACoffee} alt="Buy me a coffee" width="150" />
                    </a>
                </div>
            </div>
        </div>
    )
}