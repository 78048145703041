import React from "react";

const Footer = () => {
  let currentYear = new Date().getFullYear();

  return (
    <div className="footer">
        <p className="copyright">Hessah Alhamadi &copy; {currentYear}</p>
    </div>
  );
};

export default Footer;
