import React, { useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { certificatesData } from "../data/certificatesData";
import CertificatesItem from "../components/CertificatesItem";
import { GiSandsOfTime } from "react-icons/gi";

const Certificates = () => {
  useEffect(() => {
    // Set Meta tags
    document.title = "Certificates";
    document.querySelector('meta[name="title"]').setAttribute("content", "Certificates");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
      Fireship, course, ESM320, ESM200, ArcSight, Micro Focus, TCP/IP, Yonsei University, Coursera, Fresh Grad. Bootcamp, 
      Business awareness program, ITS, workshop, Tawteen Center, Certificates`
    );
  }, []);

  return (
    <div id="certificates" className="certificates">
      <h1>Certificates</h1>
      <div className="timeline">
        <VerticalTimeline>
          <VerticalTimelineElement
            iconStyle={{ background: "#FFFFF0", color: "#BEBEBE" }}
            icon={<GiSandsOfTime />}
          />
          {certificatesData.map((certificate, index) => (
            <CertificatesItem key={index} certificate={certificate} />
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Certificates;
