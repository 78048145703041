import coded from "../components/pictures/logos/coded.png";
import ITS from "../components/pictures/logos/ITS.png";
import AUM from "../components/pictures/logos/AUM.png";
import coursera from "../components/pictures/logos/coursera.jpeg";
import microFocus from "../components/pictures/logos/microFocus.png";
import fireship from "../components/pictures/logos/fireship.png";

export const certificatesData = [
    // Add data for other educations here
    {
        date: '2023', 
        icon: <img src={fireship} alt="fireship Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'Next.js 13 Full course', 
        subTitle: "This course covered all aspects of Next.js development, including authentication, routing, API calling, to hosting and beyond.",
        companyName: 'Fireship',
        link: 'https://fireship.io/',
        linkPlaceHolder: 'fireship.io',
    },
    {
        date: '2020', 
        icon: <img src={microFocus} alt="micro focus Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'ESM320 - ArcSight ESM 7.3 Advanced Analyst', 
        subTitle: 'This course provides expertise in using advanced ArcSight ESM tools to detect and manage security incidents. I learned to correlate event data, customize notifications, analyze events graphically, and create dynamic reports. The certification included a hands-on exam to validate my skills.',
        companyName: 'Micro Focus',
        link: 'https://www.microfocus.com/',
        linkPlaceHolder: 'microfocus.com',
    },
    {
        date: '2020', 
        icon: <img src={microFocus} alt="micro focus Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'ESM200 - ArcSight ESM 7.2 Administrator and Analyst', 
        subTitle: 'This course enabled me to master using the ArcSight ESM console and Command Center interfaces to monitor security events, configure ESM, and manage users and network intelligence resources. It also covered basic SOAR case triage, culminating in a comprehensive exam to validate my skills.',
        companyName: 'Micro Focus',
        link: 'https://www.microfocus.com/',
        linkPlaceHolder: 'microfocus.com',
    },
    {
        date: '2020', 
        icon: <img src={coursera} alt="coursera Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'Introduction to TCP/IP course', 
        subTitle: "This course covers foundational Internet technologies like IPv4, IPv6, TCP, UDP, addressing, routing, and domain names. Explore the PC/laptop's security and gateway Internet setup, and observe TCP/IP packets and security systems through a Wireshark experiment.",
        companyName: 'Yonsei University - Coursera',
        link: 'https://www.coursera.org/learn/tcpip',
        linkPlaceHolder: 'coursera.org/learn/tcpip',
    },
    {
        date: '08.2019 - 11.2019', 
        icon: <img src={coded} alt="Coded Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'Fresh Grad. Bootcamp', 
        subTitle: 'I am honored to have secured a spot among the 20 students selected from +500 participants for the Fresh Grad. Bootcamp, focusing on website & application development and sponsored by KFH, Zain, and TABCo. The selection process involved interviews, quizzes, and challenges focusing on critical thinking, logic, coding, and communication skills.',
        companyName: 'CODED Academy',
        link: 'https://www.joincoded.com/coded',
        linkPlaceHolder: 'joincoded.com',
        skills: [
            'HTML', 'CSS', 'Bootstrap', 'REST API', 'Python', 'Django', 'JavaScript', 'React', 'Redux', 'React Native',
        ],
        projects: [
            {
                title: "RobinFood",
                subTitle: "An e-commerce website and mobile application for food donations."
            },
            {
                title: "Major Quest",
                subTitle: "An online game that helps students choose their future major."
            },
        ]
    },
    {
        date: '2017', 
        icon: <img src={ITS} alt="ITS Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'Business awareness program', 
        subTitle: "This program offered a condensed externship experience, providing insights into departments like IT, development, cybersecurity, risk management, human resources, and more. We gained a valuable understanding of each department's role, contributing to a comprehensive view of organizational operations.",
        companyName: 'ITS',
        link: 'https://www.its.ws/',
        linkPlaceHolder: 'its.ws',
    },
    {
        date: '2017', 
        icon: <img src={AUM} alt="AUM Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        title: 'Effective teamwork workshop', 
        subTitle: "Participating in this workshop provided me with valuable insights into communication strategies, fostering effective teamwork, and boosting productivity, enabling me to thrive in both academic and professional aspects.",
        companyName: 'American University of the Middle East - Tawteen Center',
        link: 'https://aum.edu.kw/',
        linkPlaceHolder: 'aum.edu.kw',
    },
]