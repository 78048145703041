import React from "react";
import { Carousel } from "react-bootstrap";
import Modali, { useModali } from "modali";

const ProjectItem = ({ project }) => {
    const { title, description, cardImage, images, technologies } = project;

    // Initialize modal state and toggle function for each project
    const [modalState, toggleModal] = useModali({
        animated: true,
        overlayClose: true,
        large: true,
    });

    return (
        <article class="postcard light">
            <a class="postcard__img_link"><img class="postcard__img" src={cardImage.src} alt="yelpCamp" /></a>
            <div class="postcard__text t-dark">
                <h2 class="postcard__title">{title}</h2>
                <div class="postcard__subtitle small"></div>
                <div class="postcard__bar"></div>
                <div class="postcard__preview-txt">{description}</div>
                <div className="made-with my-2">
                    <b>Made with:</b>
                    <ul class="postcard__tagbox">
                        {technologies.map((tech, index) => (
                            <span key={index} className="mx-2">{tech}</span>
                        ))}
                    </ul>
                </div>
                <div className="mt-3 view-more">
                    <button className="btn px-2.5" onClick={toggleModal}>View More</button>
                </div>
            </div>
            {/* Modal component  */}
            <Modali.Modal {...modalState}>
                <div className="content-modal px-4">
                    <Carousel className="mb-5">
                        {images.map((image, index) => (
                            <Carousel.Item key={index} interval={1000}>
                                <img className="d-block w-100" src={image.src} alt={image.alt} loading="lazy" />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </Modali.Modal>
        </article>
    );
};

export default ProjectItem;
