// icons
import kfh from "../components/pictures/logos/kfh.png";
import phinests from "../components/pictures/logos/phinests_logo.png";
import ican from "../components/pictures/logos/ican.png";

export const experiencesData = [
    // Add data for other experiences here
    {
        date: '02.2022 - present', 
        icon: <img src={phinests} alt="Phinests Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        jobTitle: 'Software Engineer', 
        companyName: 'Phinests', 
        link: 'https://phinests.com/kw/en',
        linkPlaceHolder: 'phinests.com',
        description: "I'm handling the development and maintenance of our company's website, ensuring optimal performance and a seamless user experience across both front-end and back-end functionalities. Additionally, I've managed the back-end infrastructure of our mobile application, implementing robust and scalable solutions to enhance functionality and performance. Furthermore, I've played a key role in coordinating the integration of internal systems for our operations team and administrators, significantly improving overall efficiency.",
        skills: [
            'Ruby on Rails', 'React', 'NextJS', 'HTML', 'CSS', 'TanStack', 'Bootstrap', 'Tailwind', 'JavaScript', 'TypeScript', 'PostgreSQL'
        ]
    },
    {
        date: '02.2020 - 02.2021', 
        icon: <img src={kfh} alt="KFH Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }} />,
        jobTitle: 'SOC Analyst', 
        companyName: 'Kuwait Finance House',
        link: 'https://www.kfh.com/',
        linkPlaceHolder: 'kfh.com',
        description: "In my role as a SOC Analyst, I was tasked with conducting health checks, testing, and maintaining connectors to ensure the functionality of our security operations center infrastructure. Additionally, I collaborated with the team to develop and enhance security policies, procedures, and protocols, thereby contributing to the overall security posture of the company."
    },
    {
        date: '12.2018 - 08.2019', 
        icon: <img src={ican} alt="I Can Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }} />,
        jobTitle: 'Administrative', 
        companyName: 'I Can Center',
        description: "During my role at the 'I Can Center' for psychological, social, and educational counseling, my administrative responsibilities were diverse and impactful. These responsibilities included:",
        descriptionList: [
            'Managed appointments with therapists, ensuring smooth scheduling.',
            'Conducted follow-ups with patients and parents to ensure satisfaction.',
            'Created and curated engaging social media content, including posts, ads, and infographics.',
            'Organized workshops and developed visual content.',
            'Handled accounting tasks, conducting financial analyses.',
            "Assisted management in addressing team members' needs.",
        ]
    },
]