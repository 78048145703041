import { VerticalTimelineElement } from "react-vertical-timeline-component";

const ExperienceItem = ({ experience }) => {
    const { date, icon, jobTitle, companyName, link, linkPlaceHolder, description, skills, descriptionList} = experience;

    return (
        <VerticalTimelineElement
            className=""
            contentStyle={{ boxShadow: '2px 2px 8px 2px #BEBEBE', background: '#FFFFF0', borderRadius: '.5rem' }}
            contentArrowStyle={{ borderRight: "20px solid  #EAEAEA" }}
            date={date}
            iconStyle={{ background: "#FFFFF0", color: "#BEBEBE" }}
            icon={icon}
            style={{borderColor: "#FADCD9"}}
        >
            <h3 className="vertical-timeline-element-title experience-title">{jobTitle}</h3>
            <h4 className="vertical-timeline-element-subtitle experience-subtitle">{companyName}
                {link && <> · <a href={link} target="_blank" rel="noreferrer">{linkPlaceHolder}</a></>}
            </h4>
            {description && <p className="text-muted">{description}</p>}
            {skills && (
                <div className="experience-skills">
                    {skills.map((skill, index) => (
                        <span key={index}>{skill}</span>
                    ))}
                </div> 
            )}
            {descriptionList && <div className="mt-2">
                {descriptionList.map((item, index) => (
                    <p className="text-muted m-0" key={index}> ➺ {item}</p>
                ))}
            </div>}
            
        </VerticalTimelineElement>
    )
}

export default ExperienceItem;