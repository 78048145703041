import { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';

export default function NavBar () {
    const [navbar, setNavbar] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 60) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        // scroll navbar config
        changeBackground();
        window.addEventListener("scroll", changeBackground);

        // Add event listener for clicks outside the menu
        const handleClickOutside = (event) => {
            if (menuOpen &&!event.target.closest('.navbar-collapse')) {
                setMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen])

    return (
        <div className={navbar ? "nav-bar scrolled" : "nav-bar"}>
            <Navbar expand="sm">
                <Container fluid>
                    <Navbar.Toggle aria-controls="responsive-navbar" onClick={toggleMenu} />
                    <Navbar.Collapse id="responsive-navbar" in={menuOpen}>
                        <Nav className="me-auto my-2">
                            <Link className="nav-contents" to="/" key="home">Home</Link>
                            <Link className="nav-contents" to="/about" key="about">About</Link>
                            <Link className="nav-contents" to="/skills" key="skills">Skills</Link>
                            <Link className="nav-contents" to="/experience" key="experience">Experience</Link>
                            <Link className="nav-contents" to="/education" key="education">Education</Link>
                            <Link className="nav-contents" to="/certificates" key="certificates">Certificates</Link>
                            <Link className="nav-contents" to="/volunteer" key="volunteer">Volunteer</Link>
                            <Link className="nav-contents" to="/projects" key="projects">Projects</Link>
                            <Link className="nav-contents" to="/contact" key="contact">Contact</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}