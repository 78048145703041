import React, { useEffect } from "react";
import kfasCoded from "../components/pictures/logos/kfas-coded.jpeg";

const Volunteer = () => {
    useEffect(() => {
        // Set Meta tags
        document.title = "Volunteer";
        document.querySelector('meta[name="title"]').setAttribute("content", "Volunteer");
        document.querySelector('meta[name="keywords"]').setAttribute("content", 
          `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
          Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
          Volunteer, Technical, judge, KFAS, COVID-19, virtual, hackathon`
        );
      }, []);

    return (
        <div id="volunteer" className="volunteer">
            <h1>Volunteer</h1>
            <p className="subtitle">
                Volunteering can act as a bridge to new experiences and insights, allowing us to broaden our skill 
                sets and perspectives by deeply engaging in different contexts and challenges.
            </p>

            <div className="volunteer-container">
                <div className="volunteer-item"> 
                    <img src={kfasCoded} className="vol-img img-fluid" alt="kfas coded Logo" style={{ borderRadius: '3%', justifyContent: "center" }}/>
                    <div className="vol-text">
                        <h3 className="my-3">Technical judge in KFAS's COVID-19 virtual hackathon cooperating with CODED</h3>
                        <p>
                            My role as a technical judge involved carefully evaluating participant work task by task, 
                            ensuring fair assessment and progression, while collaborating closely with fellow judges to 
                            maintain consistency and fairness throughout the event.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Volunteer;
