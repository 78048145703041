import { VerticalTimelineElement } from "react-vertical-timeline-component";

const CertificatesItem = ({ certificate }) => {
    const { date, icon, title, subTitle, companyName, link, linkPlaceHolder, skills, projects} = certificate;

    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ boxShadow: '2px 2px 8px 2px #BEBEBE', background: '#FFFFF0', borderRadius: '.5rem' }}
            contentArrowStyle={{ borderRight: "20px solid  #EAEAEA" }}
            date={date}
            iconStyle={{ background: "#FFFFF0", color: "#BEBEBE" }}
            icon={icon}
        >
            <h3 className="vertical-timeline-element-title certificates-title">{title}</h3>
            <h4 className="vertical-timeline-element-subtitle certificates-subtitle">{companyName}
                {link && <> · <a href={link} target="_blank" rel="noreferrer">{linkPlaceHolder}</a></>}
            </h4>
            {subTitle && <p className="text-muted">{subTitle}</p>}
            {skills && (
                <div className="certificates-skills">
                    {skills.map((skill, index) => (
                        <span key={index}>{skill}</span>
                    ))}
                </div> 
            )}
            {projects && (
                <>
                    <p>Capstone Projects:</p>
                    {projects.map((project, index) => (
                        <p key={index}><b>{project.title}</b> ➺ {project.subTitle}</p>
                    ))}
                </>
            )}
            
        </VerticalTimelineElement>
    )
}

export default CertificatesItem;