import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";
import Experience from "./pages/Experience";
import Education from "./pages/Education";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Home from './pages/Home';
import Certificates from './pages/Certificates';
import Volunteer from './pages/Volunteer';
import NotFound from './pages/NotFound'
require('dotenv').config();

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/skills" element={<Skills/>} />
          <Route path="/experience" element={<Experience/>} />
          <Route path="/education" element={<Education/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/certificates" element={<Certificates/>} />
          <Route path="/volunteer" element={<Volunteer/>} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
