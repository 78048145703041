import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animation2 from "../components/lotties/Animation - 1714222158298.json";
import { Link } from 'react-router-dom';

const Home = () => {
  const anime = useRef(null); //for lottie animation

  useEffect(() => {
    // Set Meta tags
    document.title = "Hessah Alhamadi's Portfolio";
    document.querySelector('meta[name="title"]').setAttribute("content", "Hessah Alhamadi's Portfolio");
    document.querySelector('meta[name="description"]').setAttribute("content", "Hessah Alhamadi is a full-stack developer from Kuwait");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application,
      home page`
    );

    // lottie config
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation2,
    });
    return () => lottie.stop();
  }, []);

  return (
    <div id="home" className="main-div">
      <div className="header-name">
        <h1>Hessah Alhamadi</h1>
        <div className="titles">
          <p>{`< Computer Engineer />`}</p>
          <p>{`< Full-Stack Developer />`}</p>
          <h5 className="mb-0 mt-4">I'm passionate about crafting neat and user-friendly websites!</h5>
          <small>Learn more about me <Link className="here-link" to="/about" key="about">here</Link></small>
        </div>
      </div>
      <div ref={anime} className="header-pic"></div>
    </div>
  );
};

export default Home;
