import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import notFound from "../components/lotties/notFound.json";

export default function NotFound () {
    const anime = useRef(null); //for lottie animation

    useEffect(() => {
        // Set Meta tags
        document.title = "Hessah Alhamadi - Not Found Page";
        document.querySelector('meta[name="title"]').setAttribute("content", "Hessah Alhamadi - Not Found Page");
        document.querySelector('meta[name="keywords"]').setAttribute("content", 
            `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
            Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
            Not Found Page, 404`
        );

        // lottie config
        lottie.loadAnimation({
            container: anime.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: notFound,
        });
        return () => lottie.stop();
    }, []);

    return (
        <div className="not-found">
            <div ref={anime} className="gif"></div>
            <h1>Page Not Found!</h1>
        </div>
        
    )
}