import aum from "../components/pictures/logos/AUM.png";

export const educationsData = [
    // Add data for other educations here
    {
        date: '2013 - 2018', 
        icon: <img src={aum} alt="AUM Logo" style={{ marginBottom: 0, paddingBottom: 0, position: "relative", width: "100%", borderRadius: '50%', justifyContent: "center" }}/>, 
        jobTitle: 'Bachelor of Science in Computer Engineering', 
        companyName: 'American University of The Middle East, Kuwait',
        link: 'https://aum.edu.kw/',
        linkPlaceHolder: 'aum.edu.kw'
    },
]