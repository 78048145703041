import React, { useEffect } from "react";

const About = () => {
  useEffect(() => {
    // Set Meta tags
    document.title = "About Hessah Alhamadi";
    document.querySelector('meta[name="title"]').setAttribute("content", "About Hessah Alhamadi");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
      Marissa Mayer, about us`
    );
  }, []);

  return (
    <div id="about" className="about">
      <h1>About</h1>

      <div>
        <p>Hello world!</p>
        <p>
          I'm a full-stack developer from Kuwait who enjoys creating neat and creative solutions through the implementation of new technologies.
        </p>
        <p>
          I graduated from the American University of the Middle East with a Bachelor's degree in Computer Engineering. 
          During my undergraduate studies, I pursued several online courses related to technology, particularly programming and cybersecurity, 
          where I discovered my passion.
        </p>
        <p>
          My development journey was enriched by my experience at Coded Bootcamp and has since expanded through continuous learning and exploration of 
          new platforms and technologies. 
        </p>
        <p>
          My tech idol is Marissa Mayer, known for her transition from employee #20 at Google to CEO of Yahoo. Her most powerful quote, which serves 
          as my inspiration, emphasizes the importance of pushing through moments of uncertainty to achieve personal growth:
        </p>

        <div className="quote">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
              <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
            </svg>{" "}
            I always did something I was a little not ready to do. I think
            that’s how you grow. When there’s that moment of 'Wow, I’m not
            really sure I can do this', and you push through those moments,
            that’s when you have a breakthrough{" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
              <path d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z" />
            </svg>
          </p>
          <span className="text-muted">— Marissa Mayer</span>
        </div>
      </div>
    </div>
  );
};

export default About;
