import React, { useEffect } from "react";
import * as si from "react-icons/si";
import { DiNodejs } from "react-icons/di";
import { TbLetterT } from "react-icons/tb";
import { RiJavascriptFill } from "react-icons/ri";
import { BiLogoTypescript } from "react-icons/bi";

const Skills = () => {
  useEffect(() => {
    // Set Meta tags
    document.title = "Skills";
    document.querySelector('meta[name="title"]').setAttribute("content", "Skills");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
      NodeJS, ExpressJS, TanStack, Redux, TailWind, Bootstrap, MongoDB, PostgreSQL, GitHub, Jira, Insomnia, Postman, Skills`
    );
  }, []);

  return (
    <div id="skills" className="skills">
      <h1>Skills</h1>
      <p className="subtitle">
        Utilizing a blend of advanced programming languages, robust frameworks, and efficient tools, I create 
        dynamic, responsive websites optimized for seamless user experiences across devices.
      </p>

      <div className="skills-container">
        <div className="skills-item">
          <si.SiRuby className="icon" color="#2F4F4F" />
          <span>Ruby</span>
        </div>

        <div className="skills-item">
          <si.SiRubyonrails className="icon" color="#2F4F4F" />
          <span>Rails</span>
        </div>

        <div className="skills-item">
          <DiNodejs className="icon" color="#2F4F4F" />
          <span>NodeJS</span>
        </div>

        <div className="skills-item">
          <si.SiExpress className="icon" color="#2F4F4F" />
          <span>ExpressJS</span>
        </div>

        <div className="skills-item">
          <si.SiReact className="icon" color="#2F4F4F" />
          <span>React</span>
        </div>
        
        <div className="skills-item">
          <si.SiNextdotjs className="icon" color="#2F4F4F" />
          <span>NextJS</span>
        </div>

        <div className="skills-item">
          <RiJavascriptFill className="icon" color="#2F4F4F" />
          <span>JavaScript</span>
        </div>

        <div className="skills-item">
          <BiLogoTypescript className="icon" color="#2F4F4F" />
          <span>TypeScript</span>
        </div>

        <div className="skills-item">
          <TbLetterT className="icon" color="#2F4F4F" />
          <span>TanStack</span>
        </div>

        <div className="skills-item">
          <si.SiRedux className="icon" color="#2F4F4F" />
          <span>Redux</span>
        </div>

        <div className="skills-item">
          <si.SiHtml5 className="icon" color="#2F4F4F" />
          <span>HTML</span>
        </div>

        <div className="skills-item">
          <si.SiCss3 className="icon" color="#2F4F4F" />
          <span>CSS</span>
        </div>

        <div className="skills-item">
          <si.SiTailwindcss className="icon" color="#2F4F4F" />
          <span>Tailwind</span>
        </div>

        <div className="skills-item">
          <si.SiBootstrap className="icon" color="#2F4F4F" />
          <span>Bootstrap</span>
        </div>

        <div className="skills-item">
          <si.SiMongodb className="icon" color="#2F4F4F" />
          <span>MongoDB</span>
        </div>

        <div className="skills-item">
          <si.SiPostgresql className="icon" color="#2F4F4F" />
          <span>PostgreSQL</span>
        </div>

        <div className="skills-item">
          <si.SiGithub className="icon" color="#2F4F4F" />
          <span>GitHub</span>
        </div>

        <div className="skills-item">
          <si.SiJira className="icon" color="#2F4F4F" />
          <span>Jira</span>
        </div>

        <div className="skills-item">
          <si.SiInsomnia className="icon" color="#2F4F4F" />
          <span>Insomnia</span>
        </div>

        <div className="skills-item">
          <si.SiPostman className="icon" color="#2F4F4F" />
          <span>Postman</span>
        </div>
      </div>
    </div>
  );
};

export default Skills;
