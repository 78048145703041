import React, { useEffect } from "react";
import { projectsData } from "../data/projectsData";
import ProjectItem from "../components/ProjectItem";

const Projects = () => {
  useEffect(() => {
    // Set Meta tags
    document.title = "Projects";
    document.querySelector('meta[name="title"]').setAttribute("content", "Projects");
    document.querySelector('meta[name="keywords"]').setAttribute("content", 
      `full-stack developer, software developer, software engineer, computer engineer, software developer in Kuwait,Kuwait, 
      Coded Academy, AUM, Ruby on Rails, Ruby, React, JavaScript, TypeScript, Nextjs, HTML, CSS, website, application, 
      Projects, CRUD, camps, YelpCamp, Expiration Date Tracker, store, MERN-stack, e-commerce, blog, landing page`
    );
  }, []);

  return (
    <div id="projects" className="projects">
      <h1>projects</h1>
      <p className="subtitle">Some of my projects:</p>

      <div className="project-items">
        <div>
          {projectsData.map((project, index) => (
            <ProjectItem key={index} project={project} />
          ))} 
        </div>
      </div>
    </div>
  );
};

export default Projects;
